export default defineNuxtPlugin(() => {
  return {
    provide: {
      assetsUrl: (path: string): string => {
        const { ASSETS_HOST } = useRuntimeConfig().public

        return `${ASSETS_HOST}${path}`
      }
    }
  }
})
