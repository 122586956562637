import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app';
import * as Sentry from '@sentry/vue';

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  });

  return [browserTracing];
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: useRuntimeConfig().public.SENTRY_DSN as string,
      integrations: getSentryIntegrations(),
      enabled: process.dev === false,
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    Sentry.configureScope((scope) => {
      scope.setTag('Nuxt', nuxtApp.versions.nuxt);
      scope.setTag('Vue', nuxtApp.versions.vue);
    })
  }
})
