import { defineNuxtPlugin } from "nuxt/app";
import type { Device } from '@nuxtjs/device/dist/runtime/types'

export default defineNuxtPlugin((NuxtApp) => {
  const deviceSize = ((device: Device) => {
    if (device.isDesktop) {
      return 'd'
    } else if (device.isTablet) {
      return 't'
    } else {
      return 'm'
    }
  })(NuxtApp.$device as Device)

  return {
    provide: {
      deviceSize: deviceSize as 'd' | 't' | 'm'
    }
  }
})
