import {defineNuxtPlugin } from "nuxt/app"
import { useRoute } from "vue-router"

interface MarketingParamsData {
  session_id: string,
  session_started_at: string,
  source_domain: string,
  utm_params: {
    utm_source: string,
    utm_medium: string,
    utm_campaign: string,
  }
}

export class MarketingParams {
  static readonly KEY: string = 'billy/marketingParams';

  get(): MarketingParamsData {
    const data = localStorage.getItem(MarketingParams.KEY)

    return data ? JSON.parse(data)
                : {
                    session_id: '',
                    session_started_at: '',
                    source_domain: '',
                    utm_params: {
                      utm_source: '',
                      utm_medium: '',
                      utm_campaign: '',
                    }
                  }
  }

  set(data: MarketingParamsData): void {
    localStorage.setItem(MarketingParams.KEY, JSON.stringify(data))
  }

  remove(): void {
    localStorage.removeItem(MarketingParams.KEY)
  }
}

export default defineNuxtPlugin((NuxtApp) => {
  const marketingParams = (() => {
    const marketingParams = new MarketingParams()
    const query = useRoute().query

    if (query.utm_source) {
      const utmSourceParams = query.utm_source
      const utmSource = Array.isArray(utmSourceParams) ? utmSourceParams[0] : utmSourceParams
      const utmMediumParams = query.utm_medium
      const utmMedium = Array.isArray(utmMediumParams) ? utmMediumParams[0] : utmMediumParams
      const utmCampaignParams = query.utm_campaign
      const utmCampaign = Array.isArray(utmCampaignParams) ? utmCampaignParams[0] : utmCampaignParams

      const sourceDomain: string = ((referrer) => {
        if (!referrer) return ''
        const url = new URL(referrer)
        return url.hostname
      })(document.referrer)

      marketingParams.set({
        session_id: (<any>crypto).randomUUID(),
        session_started_at: (new Date()).toISOString(),
        source_domain: sourceDomain,
        utm_params: {
          utm_source: utmSource || '',
          utm_medium: utmMedium || '',
          utm_campaign: utmCampaign || ''
        }
      })
    }

    return marketingParams
  })()

  return {
    provide: {
      marketingParams: marketingParams
    }
  }
})
