export default defineNuxtRouteMiddleware(to => {
  if (import.meta.server)
    return

  const { ENVIRONMENT, STATIC_HOST } = useRuntimeConfig().public

  if (ENVIRONMENT === 'development') {
    return
  }

  if (ENVIRONMENT === 'staging') {
    return
  }

  if (STATIC_HOST !== window.location.origin) {
    return navigateTo(
      `${STATIC_HOST}${to.fullPath}`,
      {
        external: true
      }
    )
  }
})
