<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
/**
 * 背景色
 */

html {
  @apply !bg-co-gray-700;
}

body {
  @apply relative w-full overflow-x-hidden leading-base;
  @media (max-width: 767.98px) {
    @apply text-[0.875rem];
  }
}

a {
  @apply no-underline outline-none transition-all duration-500 text-co-link-color-500 break-all;
  &:hover {
    @apply no-underline text-co-link-color-600;
  }
}
</style>
