import { scriptTagsByConfig } from '../utility/scriptTags'

export default defineNuxtPlugin(async () => {
  useHead({
    script: scriptTagsByConfig({
      useCase: 'common'
    }).script,
    noscript: scriptTagsByConfig({
      useCase: 'common'
    }).noscript
  })
})

