import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(nuxtApp => {
  let observer: IntersectionObserver;

  nuxtApp.vueApp.directive('inview', {
    mounted(el, binding) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (binding.arg === 'once' && entry.isIntersecting) {
            binding.value()
            observer.unobserve(el)
          } else if (binding.arg === 'always' && entry.isIntersecting) {
            binding.value()
          }
        })
      })
      observer.observe(el)
    },
    unmounted(el, binding, vnode, prevVnode) {
      observer.disconnect()
    },
    getSSRProps() { return {} },
  })
})
