import { defineNuxtPlugin, useHead } from '#app'

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.mixin({
    data() {
      return {
        head: undefined
      }
    },
    created() {
      if (!this.$options.oldHead || this.$options.head) return

      this.$watch(
        () => {
          return this.$options.oldHead.call(this)
        },
        (newValue: any) => {
          this.head = newValue
        },
        { immediate: true, deep: true }
      )
      this.$_fetchPromise?.then(() => {
        this.head = this.$options.oldHead.call(this)
      })
      useHead(() => this.head)
    }
  })
})
