import { defineNuxtPlugin } from 'nuxt/app';

declare global {
  interface Window {
    dataLayer: [Object];
  }
}

export default defineNuxtPlugin(() => {
  const karteSendEvent = (event: string, params: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'karte_event',
      karte_event: event,
      karte_params: params
    })
  }

  return {
    provide: {
      karteSendEvent: karteSendEvent
    }
  }
})
